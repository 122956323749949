import { useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const AppLayout = () => {
  const getLocalStorage = JSON.parse(localStorage.getItem('user_details') || '{}');
  const isMobile = useMediaQuery('(max-width : 699px)');
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.pathname, 'location_pathname');


  const checkPwaWithMob = () => {
    if (isMobile && getLocalStorage?.user_token && location.pathname !== '/app/') {
        // if(){}
      navigate('/app/#/home');
      window.location.reload();
      return;
    }
  };

  useEffect(() => {
    checkPwaWithMob();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default AppLayout;
